import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';

// Import Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function CustomizeCookieConsent({ open, handleClose }) {
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        // You can handle accordion state changes here if needed
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Customize Consent Preferences</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.
                </DialogContentText>

                <Accordion onChange={handleAccordionChange}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                        <Typography>Necessary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>No cookies available at the moment.</Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion onChange={handleAccordionChange}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                        <Typography>Functional</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>No cookies available at the moment.</Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion onChange={handleAccordionChange}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                        <Typography>Analytics</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>No cookies available at the moment.</Typography>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">Reject All</Button>
                <Button onClick={handleClose} color="primary" variant="outlined">Save My Preferences</Button>
                <Button onClick={handleClose} color="primary" variant="contained">Accept All</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomizeCookieConsent;
