import React from "react";
import { Container, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

export default function TermsAndConditionsPage({ darkMode, toggleDarkMode }) {
  return (
    <Container style={{marginTop:"5%", marginBottom:"5%"}}>

      <Typography variant="h4" style={{ color: blue[500], marginBottom: "20px:" }}>
      1. TERMS AND CONDITIONS OF BUSINESS
      </Typography>

        <Typography variant="body1" paragraph>
        1.1    &emsp;&emsp;&emsp;      These Terms and Conditions of Business (the “Terms”) and the Quote (defined below) set out the basis on which Enfogen Limited (a company incorporated in England and Wales, with company number 09216179, whose registered office address is 06 Balbina Way, Fairfields, Milton Keynes, MK11 4BN) (“Enfogen”, “we”, or “us”) will provide you, the Client (defined below), with Services (defined below).
        <br/><br/>
        1.2     &emsp;&emsp;&emsp;     These Terms and the Quote (defined below) comprise the <b>agreement</b> in relation to Services to be provided by Enfogen to the Client (the “Agreement”). If there is any conflict between these Terms and the Quote, the provisions of the Quote shall take precedence.
        </Typography>

        <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>
        2. DEFINITIONS
      </Typography>
      <Typography variant="body1" paragraph>
      2.1     &emsp;&emsp;&emsp;     The following definitions and rules of interpretation apply in these Terms:       
      <br/><br/>
      (a)   <b> Client </b>: means the person, company or other entity who purchases the Services from Enfogen;
      <br/><br/>
      (b)  <b> Deliverables</b>: means the deliverables set out in the Quote, to be produced by Enfogen for the Client;
      <br/><br/>
      (c)   <b>Fees</b>: means the fees payable by the Client for the supply of the Services, in accordance with the Quote and clause 4;
      <br/><br/>
      (d)   <b>Services</b>: means the services to be provided by Enfogen to the Client, as set out in the Quote; and
      <br/><br/>
      (e)   <b>Services</b>Quote: means Enfogen’s quotation form, setting out the Services to be provided by Enfogen and the applicable Fees, that references these Terms and which is: (i) signed by both the Client and Enfogen; or (ii) otherwise approved by the Client and subsequently confirmed by Enfogen in writing.
      <br/><br/>
      2.2     &emsp;&emsp;&emsp;     Clause headings shall not affect the interpretation of the Agreement.
        <br/><br/>
        2.3   &emsp;&emsp;&emsp;       In the Agreement, unless the context otherwise requires: capitalised words and phrases shall have the meanings given in these Terms or the Quote; words importing one gender shall be treated as importing any gender; words importing individuals shall be treated as importing corporations and vice versa; words importing the singular shall be treated as importing the plural and vice versa; a reference to a statute or other law shall include references to any amendment, modification, extension, consolidation, replacement or re-enactment of; and references to “include” and “including” (or any similar term), are not to be construed as implying any limitation.
        <br/><br/>
        2.4   &emsp;&emsp;&emsp;       The Agreement shall apply to the exclusion of any other terms and conditions communicated to Enfogen (including any terms and conditions which may be printed on, or submitted with, the Client’s purchase order or similar document).
        <br/><br/>
        2.5   &emsp;&emsp;&emsp;       For the avoidance of doubt, prior to a Quote being:
        <br/><br/>
        (a)   signed by both parties; or
        <br/><br/>
        (b)   otherwise approved by the Client and subsequently confirmed by Enfogen in writing,
        <br/><br/>
        the Quote shall not be deemed an offer from Enfogen, and may be withdrawn by Enfogen at any time.
        <br/><br/>
        2.6  &emsp;&emsp;&emsp;   Once the Quote has been signed by the Client and Enfogen, or has been otherwise approved by the Client and subsequently confirmed by Enfogen in writing, the Quote shall be deemed approved (<b>“Approved”</b>), and at this point the Agreement between the parties will come into force.
        <br/><br/>
        2.7 &emsp;&emsp;&emsp;The provision of any Services not included in the Quote, or any changes to the Quote shall be subject to Enfogen and Client agreeing, in writing, to the scope of the additional or amended Services including changes to the Fees payable to Enfogen. Once such additional or amended Services and Fees (if applicable) are so agreed in writing, they shall automatically form part of the Agreement.
        <br/><br/>
        2.8 &emsp;&emsp;&emsp; The Agreement shall commence on the date on which the Quote has been Approved or, if the Services commenced prior to that date, the Agreement shall be deemed to have commenced on the first day that the Services were provided. The Agreement shall continue thereafter in full force and effect for the period set out in the Quote, subject to earlier termination in accordance with clause 7 below (the <b>“Term”</b>).
        <br/><br/>


       </Typography>
       <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>3. PROVIDING THE SERVICES</Typography>
       <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
       3.1 &emsp;&emsp;&emsp;   Enfogen shall perform the Services throughout the Term and (where relevant) shall supply Deliverables to the Client in accordance with the Quote.
<br/><br/>
3.2 &emsp;&emsp;&emsp;   Enfogen shall apply such time, attention, and reasonable skill and care as may be necessary or appropriate for its proper performance of the Services and provision of any associated Deliverables.
<br/><br/>
3.3  &emsp;&emsp;&emsp;  Nothing in the Agreement shall prevent Enfogen from providing services which are the same as or similar to the Services to any other person.
<br/><br/>
3.4  &emsp;&emsp;&emsp;   In the Agreement the “Deliverables” shall mean the final versions of the Deliverables specified in the Quote (and, where applicable, as approved by the Client) and shall not include drafts, samples or abandoned concepts (being concepts which have not been developed and encapsulated in a final version which has been approved by the Client or published by Enfogen on its behalf).
<br/><br/>
3.5 &emsp;&emsp;&emsp;   Where the Quote provides a timetable or schedule for delivery of the Services or any Deliverables, Enfogen shall use reasonable endeavours to comply with that but time shall not be of the essence. Any Services for which time for performance is not specified shall be performed by Enfogen within a reasonable period of time.
<br/><br/>
3.6 &emsp;&emsp;&emsp;    Enfogen may subcontract or delegate any of its obligations under the Agreement. For the avoidance of doubt nothing shall prevent Enfogen from engaging third party suppliers in the normal course of its business in connection with its obligations under the Agreement.
<br/><br/>
3.7 &emsp;&emsp;&emsp;    The Client will promptly supply to Enfogen (at its own expense) any Client information, materials, equipment, tools and other items reasonably required by Enfogen or necessary to provide the Services and any Deliverables (including any Client materials or information specified in the Quote). The Client shall ensure that it has all necessary rights and licences in place to enable use by Enfogen of all such Client information, materials, equipment, tools and other items for the purposes of delivering the Services.
<br/><br/>
3.8 &emsp;&emsp;&emsp;    The Client will give Enfogen clear instructions and shall ensure that its other suppliers and agencies act in willing co-operation with Enfogen where necessary to enable Enfogen to perform the Services.
<br/><br/>
3.9 &emsp;&emsp;&emsp;   If Enfogen’s performance of any of its obligations under the Agreement are prevented or delayed by any act or omission by the Client, or failure by the Client to perform any relevant obligation (<b>“Client Default”</b>):
<br/><br/>
(a)   without limiting or affecting any other right or remedy available to it, Enfogen shall have the right to suspend performance of the Services until the Client remedies the Client Default, and to rely on the Client Default to relieve it from the performance of any of its obligations in each case to the extent the Client Default prevents or delays Enfogen’s performance of any of its obligations;
<br/><br/>
(b)   Enfogen shall not be liable for any costs or losses sustained or incurred by the Client arising directly or indirectly from Enfogen’s failure or delay to perform any of its obligations as set out in this clause 3.9; and
<br/><br/>
(c)   the Client shall reimburse Enfogen on written demand for any costs or losses sustained or incurred by Enfogen arising directly or indirectly from the Client Default.
<br/><br/>
3.10  &emsp;&emsp;&emsp; The Client may notify Enfogen in writing if it reasonably believes that the Services do not conform in all respects with the description in the Quote, and request that Enfogen re-perform the Services.  Enfogen may consider such a request and agree, in its discretion, to re-perform the Services.
<br/><br/>
3.11  &emsp;&emsp;&emsp; The Client acknowledges and agrees Enfogen is not obliged to agree to any such request, and shall, without prejudice to the Client’s right to issue a claim for breach of contract, not be required to refund to the Client any part of the Fees.
     <br/><br/> </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>4. FEES AND EXPENSES</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      4.1      &emsp;&emsp;&emsp;    In consideration of the Services being supplied, the Client shall pay the Fees set out in the Quote, and as otherwise agreed by the parties in writing.
      <br/><br/>
4.2   &emsp;&emsp;&emsp;       The Client shall pay all Fees in full in advance of Enfogen providing the Services, by the date set out in the Quote. Time shall be of the essence in relation to payment of Enfogen’s Fees.
<br/><br/>
4.3   &emsp;&emsp;&emsp;       Where relevant, the parties shall agree in writing an amount that Enfogen shall be entitled to incur on its behalf in purchasing or procuring digital advertising space for the Client, in connection with the Services (the “Ad Budget”). The parties may agree in writing to amend the Ad Budget and, for the avoidance of doubt, Enfogen will not increase the Ad Budget without the Client’s prior approval.
<br/><br/>
4.4    &emsp;&emsp;&emsp;      Unless agreed otherwise in the Quote, where an Ad Budget has been agreed, the Client will enter its payment details into the digital platforms (such as LinkedIn and Facebook) from which it is agreed Enfogen (or its authorised subcontractors) shall be authorised to purchase digital advertising on its behalf, and shall give Enfogen the relevant access credentials to enable it (or its authorised subcontractors) to access the Client’s accounts on those platforms and make such purchases.
<br/><br/>
4.5    &emsp;&emsp;&emsp;      The Client acknowledges that when purchasing digital advertising space for the Client in accordance with clause 4.4 above, Enfogen (or its authorised subcontractors) does so as agent of the Client and, provided that the purchases are in accordance with the <b>Ad Budget</b>, and otherwise comply with the Quote, the Client hereby authorises Enfogen (and its authorised subcontractors) to purchase such advertising space, to determine the content of the advertising to be displayed, and to enter into the standard advertising terms of business of the relevant platforms, on behalf of the Client.
<br/><br/>
4.6   &emsp;&emsp;&emsp;       Enfogen shall be entitled to invoice the Client all additional expenses properly incurred in the supply of the Services (including travel expenses, postage, couriers, subsistence and similar expenses) subject to approval  by the Client for any expenses in excess of the limit specified in the Quote (such approval not to be unreasonably withheld or delayed). Any and all expenses listed in the Quote are deemed pre-approved.
<br/><br/>
4.7   &emsp;&emsp;&emsp;       All Fees and other sums referred to in the Agreement are exclusive of VAT (or other applicable sales tax).  The Client will pay to Enfogen such VAT (or other applicable sales tax, if any) as may be chargeable from time to time.
<br/><br/>
4.8   &emsp;&emsp;&emsp;       If the Client fails to pay any sum payable by it by the due date for payment under the Agreement (or if the Ad Budget is not made available), Enfogen shall have the right, at its option, to suspend performance of the Services until the Client pays all outstanding payments in full (or makes the Ad Budget available, as applicable).
<br/><br/>
4.9   &emsp;&emsp;&emsp;       All amounts due under the Agreement shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>5. INTELLECTUAL PROPERTY</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      
5.1   &emsp;&emsp;&emsp;       In the Agreement “IP” means all intellectual property rights in whatever media whether or not registered including: copyright, database and compilation rights, patents, trade marks, service marks, trade names, goodwill, designs, all other industrial commercial or proprietary rights and any applications for the protection or registration of those rights and all renewals, revivals and extensions existing in any jurisdiction.
<br/><br/>
5.2   &emsp;&emsp;&emsp;       Subject to clauses 5.3 and 5.5 below, Enfogen hereby grants to the Client a worldwide royalty-free, non-exclusive, non-sublicensable, licence in respect of Enfogen’s IP in any Deliverables to enable the Client to use the Deliverables in the manner specified in the Quote.
<br/><br/>
5.3   &emsp;&emsp;&emsp;       The licence granted by clause 5.2 above shall be subject to limitations on the use of any Deliverable (including limits as to the purpose(s), territory and period of use) set out in the Quote or otherwise explained by Enfogen in writing. If any such limitations are not clear from the Quote, the licence granted shall be deemed to be limited to such uses as should reasonably be contemplated by the parties given the nature of the Services.
<br/><br/>
5.4   &emsp;&emsp;&emsp;       Enfogen acknowledges that all IP in materials provided to it by or on behalf of the Client for incorporation in the Deliverables (including any Client materials and information specified in the Quote) shall remain the property of the Client or its licensors. The Client warrants that the use of such material, and the use of the Client’s name and logo, by Enfogen in accordance with the Agreement shall not infringe the rights of any third party and shall comply with all applicable law, regulation and relevant codes of practice in force from time to time.
<br/><br/>
5.5   &emsp;&emsp;&emsp;       If Enfogen intends to incorporate any third party IP into any Deliverables, it shall notify the Client of any restrictions on use of that IP. The Client shall at all times comply with such restrictions on use.
<br/><br/>
5.6   &emsp;&emsp;&emsp;       The Client agrees that Enfogen may identify the Client as a client of Enfogen (including through use of the Client’s name and logo) in its promotional materials (including the Enfogen website).
<br/><br/>
5.7   &emsp;&emsp;&emsp;       Save to the extent it is necessary to receive the Services and/or as otherwise permitted by the Agreement, the Client shall have no rights to use the name of Enfogen or any other trade marks or IP of Enfogen.  If the Client wishes to make any public statement about Enfogen or the Services, the Client agrees to seek the prior written consent of Enfogen (such consent not to be unreasonably withheld).
      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>6. LIABILITY</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      6.1     &emsp;&emsp;&emsp;     Nothing in the Agreement will limit or exclude either party’s liability (or the other party’s remedies) for: (a) death or personal injury arising from its negligence; (b) fraud or fraudulent misrepresentation; or (c) any other liability to the extent that such liability may not be excluded or limited as a matter of applicable law.
      <br/><br/>
6.2   &emsp;&emsp;&emsp;      Subject to clause 6.1 Enfogen’s maximum aggregate liability under or in relation to the Agreement (howsoever arising and including as a result of breach of contract, tort (including negligence) or statutory duty) shall not exceed 100% the Fees paid to Enfogen for the Services.
<br/><br/>
6.3    &emsp;&emsp;&emsp;      Subject to clause 6.1 Enfogen shall have no liability under or in relation to the Agreement (howsoever arising and including as a result of breach of contract, tort (including negligence) or statutory duty) for any:
<br/><br/>
(a)   direct or indirect:
<br/><br/>
(i)     loss of profits;
<br/><br/>
(ii)    loss of contracts;
<br/><br/>
(iii)   loss of business;
<br/><br/>
(iv)  loss of goodwill;
<br/><br/>
(v)   loss or corruption of data; or
<br/><br/>
(b)   indirect or consequential loss or damage of whatever nature and howsoever caused, even if Enfogen has been advised of the possibility of such damages or losses (provided that nothing in this clause 6.3(b) shall be deemed to limit or exclude our liability for direct loss or damage).
<br/><br/>
6.4   &emsp;&emsp;&emsp;    All warranties, conditions, terms, undertakings and obligations implied by statute, common law, custom, trade usage, course of dealing or otherwise are hereby excluded to the fullest extent permitted by law, including any condition of satisfactory quality or fitness for a particular purpose.
<br/><br/>
6.5   &emsp;&emsp;&emsp;   Enfogen shall not be liable for any delay or failure in the performance of its obligations caused by: (a) a failure of or delay by the Client to perform its obligations under the Agreement; (b) any compliance by Enfogen with instructions issued by the Client in relation to which Enfogen has raised a concern; or (c) any failure of, or issues with purchasing digital advertising from, any third party platforms in accordance with the Agreement.
<br/><br/>
       </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>7. TERMINATION</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      7.1     &emsp;&emsp;&emsp;     Either party may terminate the Agreement with immediate effect by notice in writing to the other if the other party:
      <br/><br/>
(a)   commits a material breach of the Agreement which is incapable of remedy or, in the case of a breach capable of remedy, the other party fails to remedy such breach within 21 days of receipt of written notice from the other party giving full particulars of the breach and requesting that the same be remedied;
<br/><br/>
(b)   becomes insolvent, enters into liquidation or bankruptcy, makes any composition or arrangement with its creditors, or takes or suffers any similar action in consequence of its debt;
<br/><br/>
(c)   (being a company) passes a resolution for its winding up (other than for the purposes of a solvent amalgamation or reconstruction), (being a partnership) is dissolved, (being a natural person) dies, has a receiver, administrator, administrative receiver, trustee or similar officer appointed over the whole or any part of its assets; or
<br/><br/>
(d)   ceases, or threatens to cease, to carry on business (other than a temporary cessation which arises in circumstances beyond that party’s control).
<br/><br/>
7.2     &emsp;&emsp;&emsp;     Enfogen may terminate the Agreement immediately on notice to the Client in writing if:
<br/><br/>
(a)   any sum due to Enfogen under the Agreement or any other current agreement between the parties is not paid on the due date and that sum remains unpaid within 14 days of a demand being sent to Client requiring payment of the unpaid sum; or
<br/><br/>
(b)   the Client makes any defamatory or derogatory statements or engages in any conduct which in the reasonable opinion of Enfogen is likely to harm or bring into disrepute the name, goodwill, reputation or image of Enfogen.
<br/><br/>
7.3      &emsp;&emsp;&emsp;    If the Quote sets out Cancellation Terms, the Client may terminate the Agreement immediately on notice to Enfogen in writing, subject to such Cancellation Terms and the payment of the applicable Cancellation Fees to Enfogen.
<br/><br/>
7.4      &emsp;&emsp;&emsp;   The expiry or termination of the Agreement shall be without prejudice to any rights or remedies available to, or any obligations or liabilities accrued to, either party at the effective date of termination.
<br/><br/>
7.5     &emsp;&emsp;&emsp;     Upon termination of the Agreement for any reason:
<br/><br/>
(a)   the Client shall pay to Enfogen all sums due to Enfogen up to and including the effective date of termination including:
<br/><br/>
(i)     any Fees for Services received by the Client prior to termination;

(ii)    any expenses and/or Costs properly incurred or committed to by Enfogen; and

(iii)   provisions of the Agreement which either are expressed to survive its termination or from their nature or context it is contemplated that they are to survive such termination, shall remain in full force and effect notwithstanding such termination.      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>8. CONFIDENTIALITY</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      8.1      &emsp;&emsp;&emsp;    Each party shall keep secret and confidential all information disclosed to it (whether in writing, verbally or otherwise) under or in connection with the Agreement which is of a confidential or proprietary nature. The receiving party shall not use, disclose, exploit, copy or modify such confidential information except for the purposes of the proper performance of the Agreement or with the prior written consent of the other party.  Any such disclosure shall be made subject to obligations equivalent to those set out in the Agreement.

8.2    &emsp;&emsp;&emsp;      Upon request of the disclosing party, the receiving party shall promptly return or destroy, as instructed, any confidential information of the disclosing party.
<br/><br/>
8.3   &emsp;&emsp;&emsp;       The obligations of confidentiality in this clause 8 shall not extend to any information that a party can show:

(a)   is in, or has become part of, the public domain other than as a result of a breach of the obligations of confidentiality under the Agreement;

(b)   was developed by it independently of any confidential information disclosed by the other party;

(c)   was independently disclosed to it by a third party entitled to disclose the same; or

(d)   is legally required to be disclosed under any applicable law.      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>9. COMPLIANCE</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 

9.1   &emsp;&emsp;&emsp;       Each party confirms that it has at all times in the past and shall throughout the Term comply with all applicable laws and regulations relating to:

(a)   anti-bribery and anti-corruption, including the Bribery Act 2010;

(b)   modern slavery, including the Modern Slavery Act 2015; and

(c)   anti-tax evasion, including the Criminal Finances Act 2017.
<br/><br/>
9.2    &emsp;&emsp;&emsp;      Each party confirms that it has, and shall maintain, in place adequate procedures designed to prevent it, and persons associated with it, from committing offences under the applicable laws and regulations referred to in clause 9.1 above, and that, if it becomes aware, or has reason to believe, that it has breached any of its obligations arising out of this clause 9, it shall notify the other party immediately.      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>10. DATA PROTECTION</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      10.1    &emsp;&emsp;&emsp;      For the purpose of this clause, the following words should have the following meanings:

<b>“Client Personal Data”</b> means personal data provided by or on behalf of the Client or otherwise obtained or generated by Enfogen on the Client’s behalf in connection with the provision of the Services;

<b>“C-P Model Clauses”</b> shall mean the standard contractual clauses as set out in EC Decision 2010/87/EU for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection (or such other standard contractual clauses approved by the UK, and/or EU authorities with respect to personal data transfers out of the UK and/or the EEA), in each case as may be amended or replaced from time to time);

<b>“Data Privacy Laws”</b> means any applicable data protection related laws and regulations, as amended, extended or re-enacted from time to time, including the following:

(a)   Data Protection Act 2018 and any legislation relating to the processing of personal data effective in the UK that is intended to replicate or maintain some or all of the provisions, rights and obligations set out in the GDPR in circumstances where the GDPR is no longer applicable in the UK  because the UK is no longer a member of the European Union;

(b)   EC Directive 2002/58/EC on Privacy and Electronic Communications;

(c)   EC Regulation 2016/679 (the <b>“GDPR”</b>) on the protection of natural persons with regard to the processing of personal data and on the free movement of such data;

(d)   all local laws or regulations implementing or supplementing the EU legislation mentioned in (b) and (c) above;

(e)   all codes of practice and guidance issued by national regulators relating to the laws, regulations and EU legislation mentioned in (a)–(d) above;

<b>“EEA”</b> means the European Economic Area;

<b>“EU”</b> means the European Union;

<b>“International Transfer Requirements”</b> means the requirements of Chapter V of the GDPR (Transfers of personal data to third countries or international organizations) and/or analogous provisions under UK Data Privacy Laws (as applicable);

<b>“Losses”</b> means losses, damages, liabilities, claims, demands, actions, penalties, fines, awards, costs and expenses (including reasonable legal and other professional expenses);

<b>“Restricted Transfer”</b> means a transfer of personal data from an entity whose processing of personal data under this Agreement is caught by the requirements of the GDPR (and/or UK Data Privacy Laws (as applicable)), to an entity located in a country, territory or jurisdiction which is not considered by the EU Commission (or in respect of personal data transfers caught by the requirements of UK Data Privacy Laws, the relevant UK governmental or regulatory body as applicable) to offer an adequate level of protection in respect of the processing of personal data pursuant to Article 45(1) of the GDPR (or analogous provisions under UK Data Privacy Laws (as applicable)); and

<b>“UK”</b> means the United Kingdom.
<br/><br/>
10.2   &emsp;&emsp;&emsp;       References in this clause to “controller”, “data subject”, “processor”, “processing”, “personal data” and “personal data breach” shall have the same meaning as defined in the GDPR.
<br/><br/>
10.4   &emsp;&emsp;&emsp;       The parties acknowledge and agree that not all personal data processed by Enfogen in connection with the Services will be subject to the GDPR (and/or the UK GDPR). Accordingly, this clause 10 shall only apply if and to the extent that Enfogen’s processing of personal data under this Agreement and in connection with the Services is subject to the GDPR and/or the UK GDPR.
<br/><br/>
10.5   &emsp;&emsp;&emsp;       The parties confirm that where Services comprise of Enfogen’s processing of Client Personal Data, Enfogen shall be the processor and Client shall be the controller with respect to such processing.
<br/><br/>
10.6   &emsp;&emsp;&emsp;       If, as a consequence of Enfogen’s provision of Services, a party considers that the relationship between them no longer corresponds to the intention of the parties stated in clause 10.5 above then it shall notify the other party and the parties shall discuss and agree in good faith such steps that may be required to confirm the parties’ intention.
<br/><br/>
10.7   &emsp;&emsp;&emsp;       Each party shall comply with the obligations imposed on it by applicable Data Privacy Laws with regard to Client Personal Data processed by it in connection with Services. Client acknowledges and agrees that Enfogen’s compliance with applicable Data Privacy Laws may be dependent on Client’s compliance with applicable Data Privacy Laws and accordingly Enfogen will not be liable for failure to comply with applicable Data Privacy Laws where such failure results from a failure of Client to comply with applicable Data Privacy Laws.
<br/><br/>
10.8   &emsp;&emsp;&emsp;       Each party shall ensure that where Services require the processing of Client Personal Data, the description of Services includes the following information:

(a)   the subject matter and duration of the processing;

(b)   the nature and purpose of the processing;

(c)   a description of the type(s) of Client Personal Data processed; and

(d)   a description of the categories of the data subjects comprised within the Client Personal Data referred to in this clause.
<br/><br/>
10.9          Enfogen shall:

(a)   only process Client Personal Data in accordance with the documented instructions of Client;

(b)   inform Client if, in Enfogen’s opinion, an instruction given by Client to Enfogen under clause 10.9(a) infringes the Data Privacy Laws;

(c)   ensure that any persons authorised by it to process Client Personal Data are subject to an obligation of confidentiality;

(d)   implement appropriate technical and organisational measures to ensure that Client Personal Data is subject to a level of security appropriate to the risks arising from its processing by Enfogen or its sub-processors, taking into account the factors and measures stated in Article 32 of the GDPR;

(e)   notify Client without undue delay after becoming aware of a personal data breach;

(f)    taking into account the nature of the processing, assist Client by implementing appropriate technical and organisational measures, insofar as this is possible, for the fulfilment of Client’s obligation to respond to requests for exercising a data subject’s rights under the GDPR;

(g)   taking into account the nature of the processing and the information available to Enfogen, assist Client with regard to Client’s compliance with its obligations under the following Articles of the GDPR:
<br/><br/>
10.3          A reference to the GDPR and/or an Article of the GDPR shall, where the context so requires and insofar as the Data Privacy Law(s) is that of the UK, be construed as a reference to the equivalent Data Privacy Law(s) of the UK and/or the corresponding provision of such Data Privacy Law(s).      


(i)                Article 32 (Security of Processing);

(ii)               Articles 33 and 34 (Notification and communication of a Personal Data Breach);

(iii)              Article 35 (Data protection impact assessment); and

(iv)              Article 36 (Prior consultation by Client with the Supervisory Authority);

(h)   upon termination of Services that required the processing of Client Personal Data (in whole or in part), at the election of Client, deliver up or destroy such Client Personal Data which is in the possession of, or under the control of, Enfogen unless EU/UK Law requires Enfogen to store such Client Personal Data; and

(i)    at the request of Client, provide Client with all information necessary to demonstrate a party’s compliance with its obligations under this Agreement.
<br/><br/>
10.10            Client shall ensure that:
(a)   the supply to Enfogen of Client Personal Data by or on behalf of the Client for the purposes of processing undertaken by Enfogen and its permitted sub-processors where such processing is authorised by Client shall comply with the Data Privacy Laws; and

(b)   the instructions given by Client to Enfogen by operation of clause 10.9(a) shall comply with the Data Privacy Laws.
<br/><br/>
<i>Costs of Assistance</i>
<br/><br/>
10.11       Where, by operation of clause 10.9, Enfogen is obliged to provide assistance to Client, or to third parties at the request of Client (including submission to an audit or inspection and/or the provision of information), such assistance shall be provided at the sole cost and expense of Client, save where such assistance directly arises from Enfogen’s breach of its obligations under this clause 10, in which event the costs of such assistance shall be borne by Enfogen.
<br/><br/>

<i>Enfogen’s appointment of sub-processors</i>


<br/><br/>
10.12       Notwithstanding any other provision of the Agreement, Enfogen shall be entitled to sub-contract any part of Services requiring the processing of Client Personal Data, subject to the following conditions in cases where the sub-contractor is not an affiliate of Enfogen:

(a)   Enfogen shall notify Client in writing of its intention to engage such sub-contractor. Such notice shall give details of the identity of such sub-contractor and the services to be supplied by it; and

(b)   Client shall be deemed to have approved the engagement of the sub-contractor if it has not served a notice in writing on Enfogen objecting (acting reasonably) to such appointment within seven days of the date that the notice is deemed to be received by Client in accordance with clause 10.12(a).

10.13   &emsp;&emsp;&emsp;    Client acknowledges and agrees that Client Personal Data may be transferred by Enfogen to third parties located in a Restricted Country, which may be considered a Restricted Transfer. In the event of the transfer being considered a Restricted Transfer, Enfogen shall ensure that the Restricted Transfer meets the International Transfer Requirements. Where Enfogen considers that C-P Model Clauses are deemed an appropriate safeguard for the Restricted Transfer pursuant to the International Transfer Requirements, Enfogen shall be deemed authorised by the Client to enter into C-P Model Clauses  in respect of that Restricted Transfer incorporating the details of the data processing, as set out in the relevant Quote, acting on behalf of the Client with the relevant third party.
<br/><br/>
10.14   &emsp;&emsp;&emsp;    The parties  acknowledge and agree that to the extent either party considers the use of the relevant lawful transfer mechanism relied on in respect of a Restricted Transfer is no longer an appropriate lawful transfer mechanism to legitimise the relevant Restricted Transfer pursuant to the International Transfer Requirements, the Restricted Transfer shall cease and the parties shall work together to agree and put in place an alternative lawful transfer mechanism or such other supplementary measures to enable the Restricted Transfer to the relevant third party to continue.
<br/><br/>
10.15    &emsp;&emsp;&emsp;   Enfogen and its affiliates shall have no liability under the Agreement for any failure to provide the relevant Services in accordance with the Agreement as a result of the suspension of such Restricted Transfer. Notwithstanding the previous sentence, if such a Restricted Transfer is suspended, the parties shall take all reasonable steps to ensure that the Services can continue to be provided in all material respects in accordance with the Agreement. Further, to the extent the parties agree that certain supplementary measures are required to legitimise the relevant Restricted Transfer, the parties shall, acting reasonably and in good faith, allocate the costs between the parties accordingly.
<br/><br/>
10.16    &emsp;&emsp;&emsp;   Enfogen’s liability under the Agreement  for the acts or omissions of any sub-processors appointed in connection with the Agreement shall be limited to an amount equal to any Losses it is able to recover from such sub-processors.

 
</Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>11. NOTICES</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      11.1          Unless expressly provided otherwise, all notices and other communications to be given under the Agreement must be in writing and shall be delivered (a) by hand (including by courier); (b) pre-paid first class post or other next day delivery service; (c) pre-paid airmail; or (d) email (sent with a delivery receipt request), to the other party’s address for notices as set out in the Quote (or such other address as that party may notify to the other from time to time). Such notice shall be treated as having been given and received:

(a)   if delivered by hand, at the time of delivery;

(b)   if sent by UK pre-paid first class post or other next day delivery service to a UK postal address, at 9.30 a.m. on the second clear day after the date of posting;

(c)   if sent by pre-paid airmail, at 9.30 a.m. on the fifth clear day after the date of posting; and

if sent by email, at the time recorded in the delivery receipt.      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>12. FORCE MAJEURE</Typography>
      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
      12.1    &emsp;&emsp;&emsp;      Neither party shall be liable to the extent that it is delayed in or prevented from performing its obligations under the Agreement to the extent that it is delayed in or prevented from doing so due to any act, event, non-happening, omission or accident beyond its reasonable control (including without limitation acts of any government, adverse weather, natural disaster, fire, explosion, epidemic, labour dispute including strikes, war, terrorist activity or civil commotion).
      </Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}> 13. ENTIRE AGREEMENT</Typography>

      <Typography variant="body1" style={{marginTop:"10px"}} paragraph> 
        13.1  &emsp;&emsp;&emsp;       The Agreement constitutes the entire agreement between the parties relating to the Services to be provided under the corresponding Quote and supersedes any prior drafts, agreements, undertakings, representations, warranties and arrangements, whether or not in writing, between the parties in relation to such Services.
        <br/><br/>
        13.2 &emsp;&emsp;&emsp;       Each of the parties acknowledges and agrees that it has not entered into the Agreement in reliance on any statement or representation of any person other than as expressly incorporated in the Agreement and that the only cause of action available to it under the terms of the Agreement shall be for breach of contract.</Typography>
      <Typography variant="h4" style={{ color: blue[500], marginTop: "20px", marginBottom: "20px:" }}>14. GENERAL    </Typography>

      <Typography variant="body1" paragraph>
        14.1  &emsp;&emsp;&emsp;    Enfogen shall be entitled to assign its rights and obligations set out in the Agreement. The Client shall not transfer its rights and/or obligations to third parties, whether in whole or in part, without Enfogen’s prior written consent
    <br/><br/>
    14.2  &emsp;&emsp;&emsp;        The failure of either party to enforce or to exercise at any time or for any period of time any term of or any right, power, privilege or remedy pursuant to the Agreement does not constitute, and shall not be construed as, a waiver of such term or right and shall in no way affect that party’s right later to enforce or to exercise it.
    <br/><br/>
    14.3   &emsp;&emsp;&emsp;       Where either party has incurred any liability to the other, whether under the Agreement or otherwise, the party incurring such liability shall not be entitled to set off the amount of such liability against any sum or sums that would otherwise be due to it under the Agreement.
    <br/><br/>
    14.4   &emsp;&emsp;&emsp;       If any provision of the Agreement is held to be invalid or unenforceable by any judicial or other competent authority, all other provisions of the Agreement will remain in full force and effect and will not in any way be impaired. If any provision of the Agreement is held to be invalid or unenforceable but would be valid or enforceable if some part of the provision were deleted, the provision in question shall apply with such modification(s) as may be necessary to make it valid and enforceable.
    <br/><br/>
    14.5   &emsp;&emsp;&emsp;       Nothing in the Agreement is intended to or shall be construed as establishing or implying any partnership, joint venture or agency of any kind between the parties, and neither party shall have authority to act in the name of or to otherwise bind the other party in any way.
    <br/><br/>
    14.6  &emsp;&emsp;&emsp;        Any variation to the Agreement will only be valid if it is in writing.
    <br/><br/>
    14.7  &emsp;&emsp;&emsp;        The parties do not intend that any term of the Agreement shall be enforceable by virtue of the Contracts (Rights of Third Parties) Act 1999 (or otherwise) by any person who is not a party to the Agreement.
    <br/><br/>
    14.8  &emsp;&emsp;&emsp;        The Agreement and any dispute or claim arising out of or in connection with it, shall be governed by and construed in accordance with the laws of England and Wales.
    <br/><br/>
    14.9  &emsp;&emsp;&emsp;      Each party irrevocably agrees, for the sole benefit of Enfogen that, subject as provided below, the courts of England and Wales shall have exclusive jurisdiction over any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Agreement or its subject matter or formation. Nothing in this clause shall limit the right of Enfogen to take proceedings against the Client in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
        </Typography>

        
    </Container>
  );
}