import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  TextField,
  ThemeProvider,
  createTheme,
  MenuItem,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import Searchbar from "../components/Searchbar";
import JobCard from "../components/Jobcard";
import LoginModal from "../components/LoginModal";
import ErrorModal from "../components/ErrorModal";

export default function HomePage({ darkMode, toggleDarkMode }) {
  const [jobData, setJobData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [userQuery, setUserQuery] = useState("");
  const [isQueryValid, setIsQueryValid] = useState(true);
  const [filterRemote, setFilterRemote] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDatePosted, setSelectedDatePosted] = useState("all");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] =useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const [errorMessageBody, setErrorMessageBody] = useState("error");

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  function buttonOnClickListenerFindJobs(event) {
    if (localStorage.getItem("jwt_token") == null) {
      setMessage("Please Login To Continue Your Job Search");
      setOpenModal(true);
    } else {
      if (!userQuery) {
        setIsQueryValid(false);
        return;
      }
      setLoading(true);

      const jwtToken = localStorage.getItem("jwt_token");
      const formattedQuery = userQuery.replace(/ /g, "+");
      const config = {
        method: "GET",
        url: "https://enfogen-backend.onrender.com/api/query/getJobsFromQuery",
        params: {
          userQuery: formattedQuery,
          ...(selectedDatePosted !== "all" && { selectedDatePosted }),
          username: localStorage.getItem("user_name"),
        },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
            if (response.data.data) {
                setJobData(response.data.data);
                setFilteredData(response.data.data);
              } else {
                if (response.data.message === "User not found" || response.data.message === "Search Limit Exhausted for the user") {
                  setOpenErrorModal(true);
                  setErrorMessage("Daily "+response.data.message);
                  setErrorMessageBody("Daily Search Limit refreshes at 12am BST");
                }
              }
              setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (jobData && jobData.length === 0) {
      axios
        .get("https://enfogen-backend.onrender.com/api/query/getDefaultJobs")
        .then((res) => {
          setJobData(res.data.data);
          setFilteredData(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [jobData]);

  const handleSearch = (query) => {
    const filteredJobs = jobData.filter(
      (job) =>
        job.job_title.toLowerCase().includes(query.toLowerCase()) ||
        job.job_description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredJobs);
  };

  const handleInputChange = (event) => {
    setUserQuery(event.target.value);
    setIsQueryValid(true);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(userQuery);
    }
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions({
      ...expandedDescriptions,
      [index]: !expandedDescriptions[index],
    });
  };

  const handleFilterRemote = () => {
    setFilterRemote(!filterRemote);
  };

  const handleDatePostedChange = (event) => {
    setSelectedDatePosted(event.target.value);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div>
        <div
          style={{
            background: darkMode ? "#333" : "#fff",
            color: darkMode ? "#fff" : "#333",
          }}
        >
          <h1 style={{ marginTop: "40px", marginBottom: "20px" }}>
            {" "}
            Your Jobs
          </h1>
          <p style={{ marginBottom: "20px" }}>
            Streamline Your Job Search with Powerful Query-Based Job Discovery
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              margin: "0 10px 10px 0",
              flexDirection: isSmallScreen ? "column" : "row",
              justifyContent: "center",
              flexWrap: "wrap",
              verticalAlign: "middle"
            }}
          >
            <TextField
              id="queryField"
              label="Enter Job Role & Location"
              variant="outlined"
              value={userQuery}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              error={!isQueryValid}
              helperText={!isQueryValid && "Query is required"}
              style={{ flex: 1, width: isSmallScreen ? "100%" : "100px", marginRight: "10px", marginTop: "10px" }}
            />
            <TextField
              select
              label="Date Posted"
              value={selectedDatePosted}
              onChange={handleDatePostedChange}
              variant="outlined"
              size="medium"
              style={{ width: isSmallScreen ? "100%" : "100px", marginTop: "10px", marginRight: isSmallScreen ? "20px" : "10px" }}
            >
              {["all", "today", "3days", "week", "month"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              onClick={buttonOnClickListenerFindJobs}
              style={{ marginTop: "10px", width: isSmallScreen ? "100%" : "auto", marginRight: isSmallScreen ? "20px" : "10px" }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Find Jobs"}
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
            <Searchbar handleSearch={handleSearch} />
            <FormControlLabel
              control={<Checkbox checked={filterRemote} onChange={handleFilterRemote} color="primary" />}
              label="Remote"
              style={{ marginLeft: "20px" }}
            />
          </div>
        </div>
        <div>
          <Grid container spacing={3} style={{marginTop:"5px"}}>
            {filteredData
              .filter((job) => (filterRemote ? job.job_is_remote : true))
              .map((job, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                  className="job-card"
                >
                  <JobCard
                    job={job}
                    expanded={expandedDescriptions[index]}
                    toggleDescription={() => toggleDescription(index)}
                    openInNewTab
                  />
                </Grid>
              ))}
          </Grid>
        </div> 
      </div>
      <LoginModal
        darkMode={darkMode}
        open={openModal}
        message={message}
        handleClose={() => setOpenModal(false)}
      />
      <ErrorModal
        darkMode={darkMode}
        open={openErrorModal}
        message={errorMessage}
        body={errorMessageBody}
        handleClose={() => setOpenErrorModal(false)}
      />
      <style jsx>{`
        .job-card {
          animation: fadeIn 0.5s ease-in;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </ThemeProvider>
  );
}
