import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { blue } from "@mui/material/colors";

export default function PrivacyPolicyPage({ darkMode, toggleDarkMode }) {
  return (
    <Container style={{marginTop:"5%", marginBottom:"5%"}}>
      <Typography variant="h4" style={{ color: blue[500] }}>
        Privacy Policy
      </Typography>

      <Box mt={3}>
        <Typography variant="body1" paragraph>
          Enfogen is committed to preserving the privacy of all visitors to
          Enfogen.com (each being “the Site”). Please read the following privacy
          policy to understand how we use and protect the information that you
          provide to us.
        </Typography>
        <Typography variant="body1" paragraph>
          <b>By registering or using this site, you consent to the collection, use
          and transfer of your information under the terms of this policy.
          </b>
        </Typography>

        <Typography
          variant="h5"
          style={{ color: blue[500], marginTop: "20px" }}
        >
          Information that we collect from you
        </Typography>
        <Typography variant="body1" paragraph>
          When you visit or register or order products or services on the Site
          you may be asked to provide certain information about yourself
          including your name, email address and contact details, and our
          external payment provider, Paypal, requires your credit or debit card
          information.
        </Typography>
        <Typography variant="body1" paragraph>
          Occasionally we may request information from users via surveys,
          contests or for a particular service (for example, our newsletter).
          Participation in surveys and contests is purely voluntary and if you
          don´t want to give us the information requested you do not have to,
          although if you choose not to give the information requested, you will
          not be able to participate in the survey or contest.
        </Typography>
        <Typography variant="body1" paragraph>
          We may also collect information about your usage of our website as
          well as information about you from comments or messages you post to
          the website and e-mails or letters you send to us.
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography
          variant="h5"
          style={{ color: blue[500], marginTop: "20px" }}
        >
          Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies are small amounts of information which we store on your
          computer, to help provide information which is targeted to your
          interests as well as allowing us to monitor website traffic and
          performance and to personalise the content of the site for you. Unless
          you have indicated otherwise, we will only place essential cookies,
          cookies which are essential to the running of the website. All other
          cookies you can opt in or out of via our cookie banner controls
          (bottom left of the website pages).
        </Typography>

        <Typography variant="body1" paragraph>
          We use the following cookies:
        </Typography>


<Typography variant="h6" style={{  marginTop: "20px" }}>Third Party Cookies</Typography>

<Typography variant="body1" paragraph>
We may also present third-party cookies, when you visit a web page with content embed content on our website from, for example, YouTube, and you may be presented with cookies from those websites.
</Typography>

<Typography variant="body1" paragraph>
Most web browsers are set up to accept cookies, but you can configure your browser to refuse cookies, although, in that case, you may not be able to use certain features on our site.

</Typography>

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>
Use of your information
</Typography>

<Typography variant="body1" paragraph>
We will use your information for a number of purposes including the following:

to process your registration and to contact you for any reasons related to the service, activity or online content that you have signed up for
to provide you with access to all parts of our site and to supply the services you have requested
to contact you about a submission you have made, including any content you provide
to provide you with information about our services, activities or online content
to improve our site content and product offerings and provide you with the best Web experience available and to provide advertisers with an efficient means of targeting the right audience
to understand how you use our site and for internal research into our users´ demographics, interests and behaviour, in order to better understand and serve our users
to use IP addresses to identify the location of users and to block disruptive use
</Typography>

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Disclosure of your information</Typography>
<Typography variant="body1" paragraph>We will not resell your information unless you have given us your specific consent to do so. Furthermore we will not share your information with any third party except as set out below:
</Typography>

<Typography variant="body1" paragraph>Third Party Service Providers. The information you provide to us will be held on our computers and may be accessed by or given to our staff working outside the UK and third parties who help us run this site or services available on this site, some of whom may be based outside of the European Economic Area
Advertisers & Business Partners. We may provide non identifiable information to advertisers and business partners
Discussion Forums. In our discussion forums or anywhere else that you comment on our site you are identified by your user or screen name.  If you choose your full name as your username, or provide personal information in any posts or comments, you will be disclosing that information to all users of the site
Legal Requests. We may disclose your information to protect our rights or where disclosure is required or permitted by law. This includes but is not limited to requests for disclosure by government bodies and law enforcement agencies, or where legally required or entitled to make a disclosure under any law or order of a court.
</Typography>

<Typography variant="body1" paragraph>Joint Ventures. If our business enters into a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.
Countries outside the European Economic Area do not always have strong data protection laws.  However, we will always take steps to ensure that your information is used by third parties in accordance with this policy.
</Typography>
 

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Security and Data Retention</Typography>
<Typography variant="body1" paragraph>We employ security measures to protect your information from access by unauthorised persons and against unlawful processing, accidental loss, destruction and damage.  While we do our best to protect you personal information, we cannot ensure or warrant the security of any information that you transmit to us. You are responsible for maintaining the secrecy of any password and account information.
</Typography>
<Typography variant="body1" paragraph>We will retain your information for a reasonable period or as long as the law requires.
</Typography>

<Typography variant="body1" paragraph>Your information may be processed and stored outside the European Economic Area. By using our site, you consent to this transfer.
</Typography>
 

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Email communications</Typography>
<Typography variant="body1" paragraph>If you have given your consent to us contacting you, we will send you emails giving information about our products and services. If at any stage you no longer wish to receive any emails from the site, you can opt out by clicking on the unsubscribe link in our email newsletters.
</Typography>
      
<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Under 18?</Typography>
<Typography variant="body1" paragraph></Typography>You may browse the Site, but you may not provide personal information to us, register with us or use our services. The Site is not designed for minors and we do not knowingly collect information from minors. If a minor has provided us with personally identifiable information, a parent or guardian of that minor may contact us if they would like this information deleted from our records. We will use reasonable efforts to delete the minor´s information from our databases.

 

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Accessing and updating</Typography>
<Typography variant="body1" paragraph>You are entitled to see the information held about you and you may ask us to make any necessary changes to ensure that it is accurate and kept up to date.  If you wish to do this, please contact us.  We are entitled by law to charge a fee of £10 to meet our costs in providing you with details of the information we hold about you.
</Typography>
 

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Changes to our Privacy Policy</Typography>
<Typography variant="body1" paragraph>
    Any changes to our privacy policy in the future will be posted to the site and, where appropriate, through e-mail notification.
</Typography>

<Typography variant="h4" style={{ color: blue[500], marginTop: "20px" }}>Contact</Typography>

<Typography variant="body1" paragraph>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to support@enfogenconsulting.com.
</Typography>
      </Box>
    </Container>
  );
}
