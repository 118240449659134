import React, { useEffect } from 'react';
import { Container, Typography, Box, Alert } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const Failurepage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 2000); // Adjust the delay as necessary

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [navigate]);

    return (
        <Container maxWidth="sm" style={{ minHeight: '73vh', textAlign: 'center', marginTop: '50px' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <CancelIcon style={{ fontSize: '64px', color: 'red' }} />
                <Typography variant="h4" gutterBottom>
                    Subscription Canceled
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your subscription process was canceled. Please try again.
                </Typography>
                <Box mt={2}>
                    <Alert severity="error">
                        There was an issue with your subscription process. Redirecting to the homepage...
                    </Alert>
                </Box>
            </Box>
        </Container>
    );
};

export default Failurepage;
