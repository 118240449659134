import React from "react";
import { Modal, Button, IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import Lottie from "react-lottie"; // Import Lottie component
import animationData from "../assets/user_errors.json"; // Import your Lottie animation file
import axios from "axios";

const ErrorModal = ({ open, handleClose, darkMode, message, body }) => {
//   const navigate = useNavigate();

  const handleModalClose = () => {
    handleClose();
    // navigate("/login");
    const config = {
        method: "POST",
        url: "https://enfogen-backend.onrender.com/api/pay/subscribe",
        // params: {
        //   userQuery: formattedQuery,
        //   ...(selectedDatePosted !== "all" && { selectedDatePosted }),
        //   username: localStorage.getItem("user_name"),
        // },
        // headers: {
        //   Authorization: `Bearer ${jwtToken}`,
        // },
        data: {
            // Add your payload here
            email:localStorage.getItem("user_email"),
            name: localStorage.getItem("user_name"),
          },
      };

      axios
        .request(config)
        .then((response) => {
            console.log("response url:",response.data)
            window.open(response.data, '_blank');
            // navigate(response);
        })
        .catch((error) => {
          console.error(error);
        //   setLoading(false);
        });
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          background: darkMode ? "#333" : "#fff",
          padding: "25px",
          borderRadius: "8px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Close button */}
        <IconButton
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: darkMode ? "#fff" : "#000",
          }}
          onClick={handleModalClose}
        >
          <CloseIcon />
        </IconButton>

        <h4 style={{marginTop:"10px"}} id="modal-modal-title">{message}</h4>
        <p id="modal-modal-body">{body}</p>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          height={150} // Adjust the height of the animation
          width={150} // Adjust the width of the animation
        />
        <Button
          variant="contained" // Make the button solid
          style={{ margin: "10px", width: "100%" }} // Occupy full width
          onClick={handleModalClose}
        >
          Buy Premium
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
