import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Brightness4 as Brightness4Icon, Brightness7 as Brightness7Icon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import lightLogo from '../assets/logo-final-light.svg';
import darkLogo from '../assets/logo-final-dark.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function Navbar({ darkMode, toggleDarkMode }) {
    const navigate = useNavigate();

    function convertISOToDaysAndMonths(isoString) {
        const date = new Date(isoString);
         const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        return `${day} ${month}`;
    }
    function calculateDaysLeft(cancellationDateString) {
        const currentDate = new Date();
        const cancellationDate = new Date(cancellationDateString);
        const differenceInTime = cancellationDate - currentDate;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    function updateSubscriptionStatus() {
        const cancellationDate = localStorage.getItem("cancellation_date");
        if (cancellationDate) {
            const daysLeft = calculateDaysLeft(cancellationDate);
            if (daysLeft <= 0) {
                localStorage.setItem("isFreeUser", "true");
                localStorage.removeItem("cancellation_date");
            }
        }
    }

    useEffect(() => {
        // Set an interval to run the updateSubscriptionStatus function at midnight UK time every day
        const now = new Date();
        const msUntilMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - now.getTime();
        
        const timer = setTimeout(() => {
            updateSubscriptionStatus();
            setInterval(updateSubscriptionStatus, 24 * 60 * 60 * 1000); // 24 hours interval
        }, msUntilMidnight);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, []);

    const cancellationDate = localStorage.getItem("cancellation_date");
    const formattedDate = cancellationDate ? convertISOToDaysAndMonths(cancellationDate) : "No date found";
    const daysLeft = cancellationDate ? calculateDaysLeft(cancellationDate) : "Unknown";
    const [anchorElSub, setAnchorElSub] = useState(null);

    const handleClick = (event) => {
        setAnchorElSub(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElSub(null);
    };

    const open_subLeft = Boolean(anchorElSub);
    const id = open_subLeft ? 'simple-popover' : undefined;

    const handleGoPremium = () => {
        const config = {
            method: 'POST',
            url: 'https://enfogen-backend.onrender.com/api/pay/subscribe',
            data: {
                email: localStorage.getItem('user_email'),
                name: localStorage.getItem('user_name'),
            },
        };

        axios
            .request(config)
            .then((response) => {
                console.log('response url:', response.data);
                window.open(response.data, '_blank');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        // eslint-disable-next-line
    }, [localStorage.getItem('user_name')]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav className={`navbar navbar-expand-lg ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'}`}>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <Link className="navbar-brand" to="/">
                        <img src={darkMode ? darkLogo : lightLogo} alt="Logo" width="100" height="50" />
                    </Link>

                    {localStorage.getItem('isFreeUser') === 'true' && localStorage.getItem("cancellation_date") == null && (
                        <button onClick={handleGoPremium} className="btn btn-primary ms-3">
                            Go Premium
                        </button>
                    )}
                </div>
                <div className="d-flex align-items-center ms-auto">
                    {/* <h5 className="me-3" onClick={() => navigate('/contact-us')}>Contact Us</h5> */}
                    {/* {console.log("localStorage.getItem(cancellation_date)!=='undefined'", localStorage.getItem("cancellation_date") !== null)} */}

                    {localStorage.getItem("cancellation_date") !== null ?
                        <div>
                            <IconButton
                                size="small"
                                aria-controls="settings-menu"
                                aria-describedby={id}
                                onClick={handleClick}
                            >
                                Days Left: {daysLeft} days
                            </IconButton>
                            <Popover
                                id={id}
                                open={open_subLeft}
                                anchorEl={anchorElSub}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography sx={{ p: 2 }}>
                                    Your Subscription Expires on {formattedDate}.
                                </Typography>
                            </Popover>
                        </div> : ""}
                    <IconButton
                        size="small"
                        aria-controls="settings-menu"
                        onClick={() => navigate('/contact-us')}
                    >
                        Contact Us
                    </IconButton>
                    <IconButton onClick={toggleDarkMode} size="large">
                        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <IconButton
                        size="small"
                        aria-controls="settings-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                    >
                        {localStorage.getItem('user_name') ? localStorage.getItem('user_name') : "Settings"}
                    </IconButton>
                    <Menu
                        id="settings-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => {
                            if (localStorage.getItem('user_name')) {
                                localStorage.clear();
                            }
                            navigate('/login');
                        }}>
                            {localStorage.getItem('user_name') ? 'Logout' : 'Login'}
                        </MenuItem>
                        {localStorage.getItem('isFreeUser') === 'false' && (localStorage.getItem("cancellation_date") == null) && (
                            <MenuItem onClick={() => navigate('/cancel-subscription')}>Cancel Subscription</MenuItem>
                        )}
                        {
                        localStorage.getItem('user_name') ? 
                        <MenuItem onClick={() => {
                            navigate('/delete-account');
                        }}>
                            Delete Account
                        </MenuItem>
                         :""
                        }
                    </Menu>
                </div>
            </div>
        </nav>
    );
}
