import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import {  useNavigate } from 'react-router-dom';

function Footer({ darkMode }) {
    const navigate = useNavigate();
    const [hover, setHover] = React.useState({privacy: false, terms: false});

    return (
        <footer className={`text-center py-3 ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
            <div className="container">
            <p>Enfogen Consulting ©2023. All Rights Reserved. Designed and Developed by Enfogen.</p>
            <div className='d-flex justify-content-center'>
            <p
        onClick={() => navigate("/privacy-policy")}
        onMouseEnter={() => setHover({...hover, privacy: true})}
        onMouseLeave={() => setHover({...hover, privacy: false})}
        style={{
          cursor: 'pointer',
          textDecoration: hover.privacy ? 'underline' : 'none',
           marginRight: '20px'
        }}
      >
        Privacy Policy
      </p>
      <p
        onClick={() => navigate("/terms-and-conditions")}
        onMouseEnter={() => setHover({...hover, terms: true})}
        onMouseLeave={() => setHover({...hover, terms: false})}
        style={{
          cursor: 'pointer',
          textDecoration: hover.terms ? 'underline' : 'none'
        }}
      >
        Terms & Conditions
      </p>
      </div>
</div>
        </footer>
    );
}

export default Footer;
