import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function InitialCookieConsent({ open, handleClose, handleShowCustomize }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAcceptAll = () => {
        // Store user's consent choice
        localStorage.setItem('cookieConsent', 'accepted');
        handleClose();
    };

    const handleRejectAll = () => {
        // Store user's consent choice
        localStorage.setItem('cookieConsent', 'rejected');
        handleClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose} 
            fullWidth 
            maxWidth="sm" 
            PaperProps={{
                style: isMobile ? { margin: '0 auto', top: 'auto', bottom: 'auto' } : { position: 'fixed', top: '65%', right: '50%' }
            }}
        >
            <DialogTitle>We value your privacy</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.
                    By clicking "Accept All", you consent to our use of cookies.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleShowCustomize} color="primary">Customize</Button>
                <Button onClick={handleRejectAll} color="secondary">Reject All</Button>
                <Button onClick={handleAcceptAll} color="primary">Accept All</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InitialCookieConsent;
