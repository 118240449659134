import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Homepage';
import LoginPage from './pages/Loginpage';
import SignupPage from './pages/Signuppage';
import Footer from './components/Footer';
import PasswordResetPage from './pages/PasswordResetPage';
import Navbar from './components/Navbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Failurepage from './pages/Failurepage';
import Successpage from './pages/Successpage';
import CancelSubscriptionpage from './pages/CancelSubscriptionpage';
import ContactUspage from './pages/ContactUspage';
import PrivacyPolicyPage from './pages/PrivacyPolicypage';
import TermsAndConditionsPage from './pages/TermsAndConditionspage';
import DeleteAccountPage from './pages/DeleteAccountPage';
import InitialCookieConsent from './cookies/InitialCookieConsent';
import CustomizeCookieConsent from './cookies/CustomizeCookieConsent';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookie } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    const [darkMode, setDarkMode] = useState(false);
    const [showInitialConsent, setShowInitialConsent] = useState(true);
    const [showCustomizeConsent, setShowCustomizeConsent] = useState(false);
    const [showCookieIcon, setShowCookieIcon] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        const consentStatus = localStorage.getItem('cookieConsent');
        console.log("consentStatus",consentStatus,consentStatus==='rejected')
        if (consentStatus === 'accepted') {
            setShowInitialConsent(false);
        }
    });

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    const mainBodyStyle = {
        background: darkMode ? '#333' : '#fff', // Example background color
        color: darkMode ? '#fff' : '#333', // Example text color
    };

    const handleCloseInitial = () => setShowInitialConsent(false);
    const handleShowCustomize = () => {
        setShowInitialConsent(false);
        setShowCustomizeConsent(true);
    };
    const handleCloseCustomize = () => {
        setShowCustomizeConsent(false);
        setShowCookieIcon(true); // Show floating cookie icon after closing customize dialog
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="App" id="outer bod">
                <Router>
                    <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} style={{padding: "0px"}} />
                    <div id="main bod" className="container" style={mainBodyStyle}>
                        <Routes>
                            <Route exact path="/" element={<HomePage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route exact path="/login" element={<LoginPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route exact path="/signup" element={<SignupPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route path="/reset/:email" element={<PasswordResetPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route path="/success" element={<Successpage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route path="/failure" element={<Failurepage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
                            <Route path="/cancel-subscription" element={<CancelSubscriptionpage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} /> 
                            <Route path="/contact-us" element={<ContactUspage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} /> 
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} /> 
                            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} /> 
                            <Route path="/delete-account" element={<DeleteAccountPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} /> 
                        </Routes>
                    </div>
                    <Footer style={{marginTop: "100px"}} darkMode={darkMode} /> {/* Add Footer component */}
                    {showCookieIcon && (
                        <IconButton
    style={{
        position: 'fixed',
        bottom: 20,
        left: 20,
        backgroundColor: '#1976d2', // MUI blue background color
        color: '#fff', // White color for icon and text
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)', // Optional shadow effect
    }}
    onClick={() => setShowCustomizeConsent(true)}
>
    <FontAwesomeIcon icon={faCookie} size="lg" /> {/* Adjust size as needed */}
</IconButton>
                    
                    )}
                </Router>
            </div>
            <InitialCookieConsent
                open={showInitialConsent}
                handleClose={handleCloseInitial}
                handleShowCustomize={handleShowCustomize}
            />
            <CustomizeCookieConsent
                open={showCustomizeConsent}
                handleClose={handleCloseCustomize}
            />
            {/* Apply mainBodyStyle to the body element */}
            <style>{`
                body {
                    padding: ${mainBodyStyle.padding};
                    background: ${mainBodyStyle.background};
                    color: ${mainBodyStyle.color};
                }
            `}</style>
        </ThemeProvider>
    );
}

export default App;
