import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  useTheme,
  Grid,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { LocationCity, AccessTime } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const JobCard = ({ job, expanded, toggleDescription }) => {
  const theme = useTheme();

  const truncateDescription = (description, maxLength) => {
    if (!description) return "N/A";
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  const convertUtcToReadableFormat = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString();
  };

  const formatEmploymentType = (type) => {
    if (!type) return "N/A"; // Handle case where type is not defined
  
    // Convert to lowercase and capitalize only the first letter
    const formattedType = type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    return formattedType;
  };

  return (
    <Card
      sx={{
        margin: "10px",
        width: "100%",
        borderRadius: "10px",
        boxShadow:
          theme.palette.mode === "dark"
            ? "0px 4px 8px rgba(0, 0, 0, 0.4)"
            : "0px 0px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: theme.palette.mode === "dark" ? "#333" : "#fff",
        color: theme.palette.mode === "dark" ? "#fff" : "#333",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              {job && job.job_title ? job.job_title : "N/A"}
            </Typography>
            <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
            {job && job.employer_logo && (
              
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={job.employer_logo}
                    alt="Icon"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                
                
              </div>
            )}
            <Typography
                  variant="subtitle2"
                  component="div"
                  style={{ marginRight: "10px" }}
                >
                  {job && job.employer_name ? job.employer_name : "N/A"}
                </Typography>
                </div>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <LocationOnIcon />
            <Typography variant="body2" component="div">
              {job && job.job_is_remote ? "Remote" : "On-Site"}
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <BusinessCenterIcon />
            <Typography variant="body2" component="div">
              {job &&
              job.job_required_experience &&
              job.job_required_experience.no_experience_required
                ? "Entry-Level"
                : job.job_required_experience &&
                  job.job_required_experience.required_experience_in_months
                ? job.job_required_experience.required_experience_in_months /
                    12 +
                  " YOE"
                : "Experienced"}
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <LocationCity />
            <Typography variant="body2" component="div">
              {(job && job.job_city ? job.job_city : "N/A") +
                ", " +
                (job && job.job_country ? job.job_country : "N/A")}
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <WorkOutlineIcon />
            <Typography variant="body2" component="div">
              {job && job.job_employment_type ? formatEmploymentType(job.job_employment_type) : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <AccessTime/>
            <Typography variant="body2" component="div">
              Created:{" "}
              {job  && job.job_posted_at_datetime_utc
                ? convertUtcToReadableFormat(
                    job.job_posted_at_datetime_utc
                  )
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body2" component="div">
              Publisher:{" "}
              {job  && job.job_publisher ?
                job.job_publisher
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {expanded
                ? job && job.job_description
                  ? job.job_description
                  : "N/A"
                : truncateDescription(job && job.job_description, 150)}
              {job &&
                job.job_description &&
                job.job_description.length > 150 && (
                  <Button onClick={toggleDescription}>
                    {expanded ? "Read Less" : "Read More"}
                  </Button>
                )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Button
          href={job && job.job_apply_link ? job.job_apply_link : "#"}
          variant="outlined"
          fullWidth
          target="_blank"
        >
          View
        </Button>
      </CardContent>
    </Card>
  );
};

export default JobCard;
