import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, CircularProgress, Alert } from '@mui/material';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

const Successpage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("isFreeUser", false);
        localStorage.removeItem("cancellation_date")
        const timer = setTimeout(() => {
            navigate('/');
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px', marginBottom: 'auto' }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CheckCircleOutline style={{ fontSize: '64px', color: 'green' }} />
                    <Typography variant="h4" gutterBottom>
                        Subscription Successful
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your subscription has been activated.
                    </Typography>
                    <Box mt={2}>
                        <Alert severity="success">
                            Redirecting to the homepage...
                        </Alert>
                        <CircularProgress style={{ marginTop: '20px' }} />
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Successpage;
