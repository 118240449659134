import React from 'react';
import TextField from '@mui/material/TextField';

export default function SearchBar({ handleSearch }) {
    const handleChange = (event) => {
        handleSearch(event.target.value); // Call handleSearch function with the input value
    };

    return (
        <TextField
            label="Filter by Title/Description"
            variant="outlined"
            fullWidth
            onChange={handleChange} // Call handleChange function on input change
        />
    );
}
